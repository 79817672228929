import { configureStore } from '@reduxjs/toolkit';
import { onboardingFormReducer } from './reducers/onboardingFormReducer';
// Import other reducers as needed

export const store = configureStore({
  reducer: {
    onboardingFormReducer,
    // Add other reducers here
  },
});
