import(/* webpackMode: "eager", webpackExports: ["OverlaysProvider"] */ "D:\\Projects\\NameStation4\\namestation-next\\node_modules\\namestation-ui\\lib\\Providers\\Overlays\\index.tsx");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\NameStation4\\namestation-next\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\app\\\\fonts.ts\",\"import\":\"IBM_Plex_Sans\",\"arguments\":[{\"weight\":[\"400\",\"500\"],\"subsets\":[\"latin\",\"cyrillic\"],\"variable\":\"--font-base\"}],\"variableName\":\"plex\"}");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\NameStation4\\namestation-next\\node_modules\\namestation-ui\\lib\\Components\\Tabs\\styles.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\NameStation4\\namestation-next\\src\\assets\\img\\logo\\logo.png");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\NameStation4\\namestation-next\\src\\assets\\img\\logo\\open-graph-1500x1500.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\NameStation4\\namestation-next\\src\\components\\Layout\\index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToasterProvider"] */ "D:\\Projects\\NameStation4\\namestation-next\\src\\components\\ui\\Toaster\\index.tsx");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\NameStation4\\namestation-next\\src\\styles\\index.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\NameStation4\\namestation-next\\src\\styles\\custom.css");
