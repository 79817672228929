'use client';
import * as React from 'react';
import LinkWrapper from './link-wrapper';
import FrameSite from 'namestation-ui/lib/Frames/Site';
import Icon from 'namestation-ui/lib/Components/Icon';
import { IButtonProps } from 'namestation-ui/lib/Components/Button';
import { useRouter, usePathname } from 'next/navigation';
import { useState, useEffect } from 'react';
import LayoutInteractive from './interactive';
import { Overlays } from 'namestation-ui/lib/Providers/Overlays';
import { urls } from 'data/urls';
import ReactGA from 'react-ga4';
import { trackPageView } from 'common/customFunctions';
import { settings } from 'data/settings';
import { ITabsTabProps } from 'namestation-ui/lib/Components/TabsTab';
import CustomScroller from 'namestation-ui/lib/Components/Scroller';
import { store } from '@/store';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';

interface LayoutProps {
  children: React.ReactNode;
}

interface HistoryPath {
  pathname?: string;
  hash?: string;
}

type HistoryPathArg = string | HistoryPath;

interface IWithOverlays {
  overlaysActions: (params: { action: string; name: string }) => void;
  openOverlays: string[];
}

const skipLinkStyles = {
  'position': 'absolute',
  'left': '-9999px',
  'top': 'auto',
  'width': '1px',
  'height': '1px',
  'overflow': 'hidden',
  '&:focus': {
    position: 'fixed',
    top: '0',
    left: '0',
    width: 'auto',
    height: 'auto',
    padding: '1rem',
    background: '#fff',
    zIndex: 9999,
  },
} as const;

const Layout: React.FunctionComponent<LayoutProps> = (props) => {
  const router = useRouter();
  const pathname = usePathname();
  const overlaysContext = React.useContext<IWithOverlays>(Overlays as unknown as React.Context<IWithOverlays>);
  const { overlaysActions } = overlaysContext;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const CustomLink = React.useMemo(() => LinkWrapper, []);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  ReactGA.initialize(settings.googleAnalyticsId);

  useEffect(() => {
    // Check for JWT token in cookie
    const checkAuthStatus = () => {
      const token = Cookies.get('jwt'); // Replace with your actual cookie name
      setIsAuthenticated(!!token);
    };

    checkAuthStatus();

    // Optional: periodically check auth status
    const interval = setInterval(checkAuthStatus, 60000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (pathname) {
      trackPageView(pathname);
    }
  }, [pathname]);

  const hasHiddenHeader = false;

  // Menu items configuration
  const menuItems = [
    { id: 'home', label: 'Home', href: urls.home, ariaLabel: 'Navigate to Home page', isActive: pathname === urls.home },
    {
      id: 'generators',
      label: 'Domain Search',
      href: urls.domainSearch,
      ariaLabel: 'Search for domains',
      isActive: pathname?.startsWith(urls.domainSearch),
    },
    {
      id: 'collections',
      label: 'Names',
      href: urls.names,
      ariaLabel: 'Browse name collections',
      isActive: pathname?.startsWith(urls.names),
    },
    {
      id: 'contests',
      label: 'Contests',
      href: urls.contests,
      ariaLabel: 'View naming contests',
      isActive: pathname?.startsWith(urls.contests),
    },
    {
      id: 'pricing',
      label: 'Pricing',
      href: urls.pricing,
      ariaLabel: 'View pricing plans',
      isActive: pathname?.startsWith(urls.pricing),
    },
    {
      id: 'about',
      label: 'About',
      href: urls.about,
      ariaLabel: 'Learn about NameStation',
      isActive: pathname?.startsWith(urls.about),
    },
  ] as unknown as ITabsTabProps[];

  // Header buttons configuration
  const headerButtons: IButtonProps[] = !hasHiddenHeader
    ? !isAuthenticated
      ? [
          /*  {
          title: 'List your agency',
          href: urls.login,
          layout: 'link',
          type: 'anchor',
          intent: 'discrete',
          theme: 'default',
          link: CustomLink,
        }, */
          /*  {
          title: 'Log In',
          href: urls.login,
          
          type: 'anchor',
          intent: 'discrete',
          theme: 'default',
          link: CustomLink,
          className: 'always',
        }, */
          {
            title: 'Get Niche Names',
            layout: 'default',
            href: urls.signup,
            type: 'anchor',
            intent: 'default',
            theme: 'default',
            link: CustomLink,
            className: 'always',
          },
        ]
      : [
          {
            title: 'Dashboard',
            layout: 'default',
            href: urls.homeInternal,
            type: 'anchor',
            intent: 'default',
            theme: 'default',
            link: CustomLink,
            className: 'always',
          },
        ]
    : [];

  // Mobile menu handlers
  const handleMobileMenuOpen = () => {
    setIsMobileMenuOpen(true);
    overlaysActions({ action: 'open', name: 'mobilemenu' });
  };

  const handleMobileMenuClose = () => {
    setIsMobileMenuOpen(false);
    overlaysActions({ action: 'close', name: 'mobilemenu' });
  };

  // Navigation configuration
  const locationObject = {
    pathname: String(pathname || '/'),
    search: '',
    hash: isMobileMenuOpen ? '#mobilemenu' : '',
    state: null,
    key: '',
    toString: function () {
      return String(this.pathname);
    },
  };

  const historyObject = {
    push: (path: HistoryPathArg) => {
      if (typeof path === 'string') {
        if (path.includes('#mobilemenu')) {
          handleMobileMenuOpen();
        } else {
          router.push(path);
        }
      } else if (path && 'pathname' in path && path.pathname) {
        router.push(path.pathname);
      }
    },
    replace: (path: HistoryPathArg) => {
      if (typeof path === 'string') {
        if (!path.includes('#mobilemenu')) {
          router.replace(path);
        }
      } else if (path && 'pathname' in path && path.pathname) {
        router.replace(path.pathname);
      }
    },
    location: locationObject,
    listen: () => () => {},
    go: () => {},
    goBack: handleMobileMenuClose,
    goForward: () => router.forward(),
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleMobileMenuClose();
    }
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [isMobileMenuOpen]);

  return (
    <React.Fragment>
      <a
        href="#main-content"
        className="skip-link"
        style={skipLinkStyles}
      >
        Skip to main content
      </a>
      <FrameSite
        logoArea={
          <CustomLink
            className="logo"
            to={urls.home}
            aria-label="NameStation Home"
          >
            <Icon
              width={140}
              height={20}
              kind="logotype"
              aria-hidden="true"
            />
          </CustomLink>
        }
        menuitems={menuItems}
        buttons={headerButtons.map((button) => ({
          ...button,
          'aria-label': `${button.title} button`,
        }))}
        title="NameStation"
        logoHref={urls.home}
        link={CustomLink}
        history={historyObject}
        location={locationObject}
        aria-label="Main navigation"
      >
        <main
          id="main-content"
          role="main"
        >
          <Provider store={store}>{props.children}</Provider>
        </main>
      </FrameSite>
      <LayoutInteractive />
    </React.Fragment>
  );
};

Layout.displayName = 'Layout';

export default Layout;
